import $ from 'jquery';

document.addEventListener("turbo:load",function(){
  if (document.querySelector(".mouse-cursor")) {
    const e = document.querySelector(".cursor-inner"),
        t = document.querySelector(".cursor-outer");
    let n, i = 0,
        o = !1;

    document.addEventListener("onmousemove",function(s){
      o || (t.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)"), e.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)", n = s.clientY, i = s.clientX
    });

    document.addEventListener("mouseenter", "a,.know_tm_topbar .trigger, .cursor-pointer", function () {
      e.classList.add("cursor-hover"), t.classList.add("cursor-hover")
    });

    document.addEventListener("mouseleave", "a,.know_tm_topbar .trigger, .cursor-pointer", function () {
      document.querySelector(this).is("a") && document.querySelector(this).closest(".cursor-pointer").length || (e.classList.remove("cursor-hover"), t.classList.remove("cursor-hover"))
    });

    e.style.visibility = "visible";
    t.style.visibility = "visible";
  }
})