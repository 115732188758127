import $ from 'jquery';

let mobile = window.matchMedia("(max-width: 1024px)");
responsivemenu(mobile);
mobile.addListener(responsivemenu);

function responsivemenu(mobile) {
  let wrapMenu = $(".header__right");
  let navExtw = $(".nav-extend.active");
  let navExt = navExtw.children();

  if (mobile.matches) {
    $("#main-nav")
      .attr("id", "main-nav-mobi")
      .appendTo("#header_main")
      .hide()
      .children(".menu")
      .append(navExt)
      .find("li:has(ul)")
      .children("ul")
      .removeAttr("style")
      .hide()
      .before('<span class="arrow"></span>');
  } else {
    $("#main-nav-mobi")
      .attr("id", "main-nav")
      .removeAttr("style")
      .prependTo(wrapMenu)
      .find(".ext")
      .appendTo(navExtw)
      .parent()
      .siblings("#main-nav")
      .find(".sub-menu")
      .removeAttr("style")
      .prev()
      .remove();
    $(".mobile-button").removeClass("active");
    $(".mobile-button-style2").removeClass("active");
    $(".sub-menu").css({ display: "block" });
  }
}

document.addEventListener("turbo:load",function(){
  responsivemenu(mobile);

  $(document).on("click", ".mobile-button", function () {
    $(this).toggleClass("active");
    $("#main-nav-mobi").slideToggle();
    if (wrapMenu.hasClass("active")) {
      $("div.wrapper").addClass('bg-transparent');
    }
  });

  $(document).on("click", ".mobile-button-style2", function () {
    $(this).toggleClass("active");
    $("#main-nav-mobi").slideToggle();
    if (wrapMenu.hasClass("active")) {
      $("div.wrapper").addClass('bg-transparent');
    }
  });

  $(document).on("click", "#main-nav-mobi .arrow", function () {
    $(this).toggleClass("active").next().slideToggle();
  });
});
