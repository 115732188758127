import $ from 'jquery';
import Swiper from 'swiper/bundle';

const default_breakpoints = {
  0: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  350: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
};

function initialize_swipper(query_selector, nextEl, prevEl, breakpoints = {}) {
  var new_breakpoints = $.extend(default_breakpoints, breakpoints);
  var swiper = new Swiper(query_selector, {
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },
    breakpoints: new_breakpoints,
    loop: true,
  })
};

document.addEventListener("turbo:load",function(){
  let swipperTriggerList = [].slice.call(document.querySelectorAll('.feed-swiper'))
  let swipperList = swipperTriggerList.map(function (swipperTriggerEl) {
    var nextButtonSelector = swipperTriggerEl.nextElementSibling;
    var prevButtonSelector = nextButtonSelector.nextElementSibling;
    return initialize_swipper(`.${swipperTriggerEl.classList.value.replace(/\s/g, ".")}`, `.${nextButtonSelector.classList.value.replace(/\s/g, ".")}`, `.${prevButtonSelector.classList.value.replace(/\s/g, ".")}`);
  })

  var swiper = new Swiper(".swiper-item-1", {
    loop: true,
    direction: "vertical",
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      500: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    autoplay: {
      delay: 1,
    },
    speed: 3000,
  });
  $(".swiper-item-1").hover(
    function () {
      this.swiper.autoplay.stop();
    },
    function () {
      this.swiper.autoplay.start();
    }
  );
  var swiper = new Swiper(".swiper-item-2", {
    loop: true,
    direction: "vertical",
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      500: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    autoplay: {
      delay: 1,
      reverseDirection: true,
    },
    speed: 3000,
  });
  $(".swiper-item-2").hover(
    function () {
      this.swiper.autoplay.stop();
    },
    function () {
      this.swiper.autoplay.start();
    }
  );

  var swiper = new Swiper(".swiper-video-1", {
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      350: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 9,
        spaceBetween: 20,
      },
    },
    autoplay: {
      delay: 1,
    },
    speed: 4000,
  });
  $(".swiper-video-1").hover(
    function () {
      this.swiper.autoplay.stop();
    },
    function () {
      this.swiper.autoplay.start();
    }
  );
  var swiper = new Swiper(".swiper-video-2", {
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      350: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 9,
        spaceBetween: 20,
      },
    },
    autoplay: {
      delay: 1,
      reverseDirection: true,
    },
    speed: 3000,
  });
  $(".swiper-video-2").hover(
    function () {
      this.swiper.autoplay.stop();
    },
    function () {
      this.swiper.autoplay.start();
    }
  );

  var swiper = new Swiper(".service-swiper", {
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  var swiper = new Swiper(".top-banner-swiper", {
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: true,
    speed: 3000,
  });

  var swiper = new Swiper(".brower-top-swiper", {
    navigation: {
      nextEl: ".swiper-button-next.s1",
      prevEl: ".swiper-button-prev.s1",
    },
    loop: true,

    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 6,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 9,
        spaceBetween: 0,
      },
    },
  });

  var swiper = new Swiper(".content-show-feed-swipper", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      0: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      450: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      550: {
        slidesPerView: 7,
        spaceBetween: 20,
      },

      600: {
        slidesPerView: 8,
        spaceBetween: 20,
      },

      767: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
      1130: {
        slidesPerView: 9,
        spaceBetween: 20,
      },
      1300: {
        slidesPerView: 10,
        spaceBetween: 20,
      },
    },


  });
});
